.OuterContainer {
    margin: 1rem auto;
    width: 100vw;
    /* height: 100vh; */
    max-width: 100%;
    box-sizing: border-box;
    /* width: 100%; */
    /* max-width: 1000px; */
    /* padding: 0 5px; */
}

.Body {
    /* margin: 1rem auto; */
    margin-top: 2rem;
    padding: 0 0.5rem;
}

@media only screen and (min-width: 849px) {
    .Body {
        padding: 0 2rem;
        margin-top: 50px;
    }
    .OuterContainer {
        margin: 1rem auto;
        max-width: 1000px;
        padding: 0 1rem;
    }
    

}