.Main {
    /* max-width: 90%; */
    /* width: 90%;
    padding: 10px; */
    /* border: 1px solid silver; */
    margin: auto;
    align-items: center;
    position: relative;
}

.InnerContainer {
    max-width: 95%;
    margin: auto;
}

.Container {
    /* max-width: 300px; */
    margin: auto;
    padding: 0 15px;
    display: flex;
    /* flex-flow: row wrap; */
    /* align-items: flex-start; */
    justify-content: center;
    flex-flow: column;
    align-items: center;
}

.ContainerItem {
    /* min-width: 350px; */
    width: 95%;
    /* max-width: 50%; */

    /* margin: 10px; */
    /* margin: 5px; */
}

@media only screen and (min-width: 449px) {

    .ContainerItem:first-child {
        padding-top: 32px;
    }

    .ContainerItem {
        max-width: 400px;
        margin: 0 25px;
    }


}

.Title {
    text-transform: capitalize;
    font-weight: bold;
    padding: 10px 0;
    /* text-align: left; */
    color: #F5881F;
    /* font-size: 1.1rem; */
}

.TitleLabel {
    text-align: left;
    font-size: 1rem;
    color: #464646;
    padding: 15px 0 15px 0;
    font-weight: bold;
}

.InputRow {
    
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    border: 1px solid rgb(228, 226, 226);
    border-radius: 5px;
    position: relative;
    padding: 25px;
    background-color: rgb(255, 255, 255);
}

.TranscribeBoxTitle {
    font-size: .8rem;
    font-weight: bold;
    color: #F48620;
    position: absolute;
    left: 5px;
    top: 2px;
    /* bottom: 0; */
}

.Input {
    margin: 2px;
    width: 100px;
    font-size: .8rem;
    border-radius: 0;
}

.OriginRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    justify-content: center;
}

.OriginLabel {
    padding-right: 10px;
    text-transform: capitalize;
    font-weight: bold;
    font-size: .9rem;
    min-width: 70px;
    color: #F48620;
}

.OriginInput {
    margin: 5px auto;
    max-width: 250px;
    font-size: .7rem;
    margin: 2px;
    border-radius: 0;

}


.Incorrect {
    background-color: #f4862025;
}


.Correct {
    border-color: #c8f2cd;
    color: #F48620;
    transition: background-color .5s ease-out;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F);    
}




.Button {
    /* background: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F); */
    border: 10px solid;
    border-image-slice: 1;
    border-width: 2px;
    border-image-source: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F);
    color: #F48620 ;
    background-color: white;
    /* margin: 5px; */
    display: block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 0.375rem 0.75rem;
    width: 100%;
}


.Button:hover {
    background: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F);
    color: white;
    cursor: pointer;
}


.ButtonSecondary {
    background: none;
    border: 2px solid #464646;
    color: #464646;
}

.ButtonSecondary:hover {
    color: white;
    /* border: 2px solid white; */
    background: none;
    background-color: #464646;

}

.MultiChoiceAnswer {
    /* color: #464646; */
    color: #F48620 ;
    /* max-width: 300px; */
    padding: 10px;
    background-color: white;
    /* border-radius: 5px; */
    box-shadow: 2px 2px 5px #aca8a8;
    margin: 10px auto;
    transition: background-color .2s ease-out;
    /* border: 1.5px solid #fff; */
    border: none;
}


/* .MultiChoiceAnswer:hover {
    border: 1.5px solid #c7c7c7;
    background-color: #9e9e9e;
    color: white;
    cursor: pointer;
    background: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F);
    color: white;
} */

.MultiChoiceAnswer:hover:not(.IncorrectMulti):not(.GameEnded):not(.CorrectMulti) {
    cursor: pointer;
    /* border: 1.5px solid #c7c7c7; */
    /* background-color: #9e9e9e;
    color: white; */
    background: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F);
    color: white;
}

@media (hover: none) {
    .MultiChoiceAnswer:hover:not(.IncorrectMulti):not(.CorrectMulti) { 
        color: #F48620 !important; 
        background: white !important;
    }
  }

.IncorrectMulti {
    background-color: rgb(193, 193, 193);
    /* border: 1.5px solid rgb(160, 64, 64); */
    color: white;
    /* transition: background-color .2s ease-out; */
    
}

.CorrectMulti {
    background: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F);
    color: white;
    transition: background-color .5s ease-out;
}

.AccentOnlyContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: .9rem;
    width: 100%;
}

.AccentToggleLabels {
    flex-basis: 0;
}

.VictoryContainer:hover {
    cursor: pointer;
}

.ShowTextTranslated {
    color: grey;
    font-size: .7em;
}

.SelectTypesContainer {
    text-align: left;
    /* margin: 0; */
    /* padding: 0; */
    /* font-size: .6rem; */

}

.SelectTypesButton {
    /* text-align: right; */
    margin-top: -30px;
    /* padding: 0; */
    font-size: .7rem;
    border-color: lightgrey;

}

.LineTypeForm {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 100%;
}

.LineTypeFormButton {
    border-radius: 5px;
    border: 1px solid rgb(231, 231, 231);
    color: lightgrey;
    padding: 7px;
    font-size: .8rem;
    display: inline-flex;
    align-items: center;   
    justify-content: center;  
    margin: 2px;
    flex-grow: 1;
    
}

.LineTypeFormButton:hover {
    cursor: pointer;
    /* background-color: #464646;
    color: white; */
}

.SelectedLineTypeButton {
    /* background-color: #5a73cc; */
    border: 1px solid #464646;
    color: #464646;
    color: #fff;
    background-color: #464646;
    /* font-weight: bold; */
}

/* .SelectedLineTypeButton:hover { */
    /* background-color: rgb(234, 234, 234); */
    /* color: rgb(96, 93, 93); */
    /* border: 1px solid lightgrey; */
/* } */

.VictoryContainer {
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Footer {
    /* position: fixed; */
    position: sticky;
    bottom: 0; 
    left: 0;
    right: 0;
    width: 100%;
    background-color: white;
    padding: 10px 0;
    border-top: .5px solid rgb(235, 235, 235);
}

.FooterWidthControl {
    /* max-width: 90%; */
    margin: auto;

}

.FooterButtons {
    flex-direction: row;
    display: flex;
}

.FinalFooter {
    padding-bottom: 400px;
}

@media only screen and (min-width: 699px) {


    .FooterWidthControl {
        max-width: 500px;
        /* margin: auto; */
    
    }

}

.SCC {
    width: 100%;
    display: block;
    /* border: 1px solid #000; */
    /* padding: 10px; */
    /* font-family: Sans-serif; */
  }
  .SCC audio { 
    width: 100%; display: block; 
  }
  .SCC div {
    display: flex;
    padding: .5em 0;
    gap: 5px;
  }
  .SCC label { flex: 1; }
  .SCC input[type="range"] { 
      flex: 5; 
    }

  .SCC span {
    flex: 1;
    text-align: center;
  }