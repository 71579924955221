.Modes {
    margin: auto;
    align-items: center;
    position: relative;
    width: 90%;
}


@media only screen and (min-width: 449px) {

    .Modes {
        max-width: 600px;
    }


}



.Banner {
    position: fixed;
    z-index: 500;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    padding: 10px;
    width: 100%;
    margin: auto;
    top: 0;
    left: 0;
}

.BannerHidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
  }

  .BannerVisible {
    visibility: visible;
    opacity: 1;
  }  