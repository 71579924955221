.StreakSummary {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 350px;
    margin: auto;
}

.Item {
    margin: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.DayLabel {
    font-weight: 450;
    padding-top: 10px;
    font-size: .9rem;
}