.App {
  text-align: center;
}


/* manual override for react-toggle component styling */
/* .react-toggle--checked  */
.react-toggle-track {
    background: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F) !important;
}

.react-toggle--checked .react-toggle-thumb {
    border-color: #F48620 !important;
}

.react-toggle-track-x, .react-toggle-track-check {
    display: none;
}

/* .react-toggle-track {
    background-color: pink !important;
} */

/* .ReactFlagsSelect-module_selectBtn__19wW7 {
    border: 10px solid !important;
    border-image-slice: 1 !important;
    border-width: 2px !important;
    border-image-source: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F) !important;
    color: #F48620 !important;
} */

.test {

    border-image-source: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F);
}
/* styles for range input on audio playback slider */
input[type=range] {
    height: 32px;
    -webkit-appearance: none;
    /* margin: 10px 0; */
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #B6B6B6;
    border-radius: 25px;
    border: none;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #828282;
    border: none;
    height: 30px;
    width: 30px;
    border-radius: 20px;
    background-image: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #B6B6B6;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #B6B6B6;
    border-radius: 25px;
    border: none;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #828282;
    border: none;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-image: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F);
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #B6B6B6;
    border: none;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-fill-upper {
    background: #B6B6B6;
    border: none;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000;
  }
  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #828282;
    border: none;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-image: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F);
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #B6B6B6;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #B6B6B6;
  }


  /* Override/additional styles for react-tabs */
  .MuiTab-root.Mui-selected {
      color: #F5881F !important;
  }

  .MuiTabs-indicator {
      background-color: #F5881F !important;
  }