.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 80%;
    border: 1px solid lightgrey; 
    margin-top: 10px;
    /* border-radius: 5px; */
    box-shadow: 5px 5px 10px #504f4f;
    padding: 40px;
    left: 10%;
    top: 20%;
    box-sizing: border-box;
    transition: all 0.3s ease-in;
    max-height: 60%;
    overflow-y: auto;
    font-size: .8rem;
}

@media (min-width: 800px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}