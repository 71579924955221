.Toolbar {
    height: 56px;
    /* width: 100%; */
    position: relative;
    top: 0;
    left: 0;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
}


.Toolbar nav {
    height: 100%;
    width: 100%;
}

.Logo {
    /* height: 80%; */
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 849px) {
    .DesktopOnly {
        display: none;
    }
}