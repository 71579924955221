.Streak {
    width: 100%;
    display: flex;
    flex-flow: row;
    font-size: .8rem;
    justify-content: space-between;
    padding: 0 5px 10px 5px;
    padding-bottom: 10px;
}

/* .FadeIn {
    background-color: rgb(74, 162, 69);
    color: white;
}

.FadeOut {
    background-color: white;
    color: inherit;
    transition: color, background-color 1s ease;
}  */