.About {
    max-width: 350px;
    margin: auto;
    padding: 10px;
    text-align: justify;
    text-justify: inter-word;    
}

@media only screen and (min-width: 849px) {
    .About {
        margin-top: 50px;
    }

}

/* .MakeLogo {
    color: #ffffff;
    background: linear-gradient(to top right, #ED3A67, #FCBE58, #F5881F);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 30rem;
} */