.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    font-size: 1.4rem;
    /* overflow: hidden; */
    /* white-space: nowrap; */
}

.NavigationItem a {
    color: #464646;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
}



.NavigationItem a:hover {
    color: rgb(177, 177, 177);
}



@media (min-width: 849px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavigationItem a {
        /* color: white; */
        height: 100%;
        padding: 16px 10px;
        border-bottom: 4px solid transparent;
    }
}

