.Logo {
    /* background-color: white; */
    padding: 3px;
    margin: auto;
    height: 100%;
    display: flex;
    flex-flow: row;
    font-size: .5rem;
    align-items: center;
    /* color: #464646; */
}

.Logo img {
    /* height: 100%; */
    height: 60px;
    /* height: auto; */
}

/* .Logo h3 {
    align-items: center;
    color: white; 
    line-height: 38px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-decoration: none;
}

 */

.Title {
    color: #ffffff;
    background: linear-gradient(to top right, #ED3A67, #FCBE58, #F5881F);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.8rem;
}