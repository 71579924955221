.NavigationItems {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.NavigationItemsLeft {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.LeftAlignItems {
    justify-self: flex-start;
}

@media (min-width: 849px) {
    .NavigationItems {
        flex-flow: row;
        justify-content: flex-end;
    }
    .NavigationItemsLeft {
        flex-flow: row;
    }
    
}

.ToggleLabel {
    font-size: .9rem;
}


