.Socials {
    display: flex;
    /* flex-flow: row wrap; */
    /* justify-content: center; */
    flex-flow: column;
    justify-content: stretch;
    padding-top: 15px;
    margin: auto;
    padding: 20px 0;
    text-align: center;
}

.Title {
    font-size: .8rem;
    font-weight: bold;
    /* color: darkgrey; */
    padding-bottom: 10px;
}

.SocialsRow {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    
}

.SocialItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: .7rem;
    flex-basis: 200px;
}

.Text {
    margin: 0 5px;
}

.Or {
    /* flex-basis: 1; */
    /* margin: 0 10px; */
    min-width: 10px;
    font-size: .8rem;
    font-weight: bold;
}

.CoffeeIcon {
    font-size: 40px;
}

.SocialItem a {
    text-decoration: none;
}

