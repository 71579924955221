/* .Container {
    text-align: left;
    z-index: 0;
    background-color: white;
} */

.Title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 1rem;
    color: #464646;
    background-color: white;
    font-weight: bold;   
    padding: 10px 0;
    /* padding-bottom: 15px;  */
}

.TopDrawer {
    width: 100%;
    /* background-color: pink; */
    /* margin: 10px auto; */
    margin: 0 auto;
    /* left: 0; */
    /* top: 0; */
    /* z-index: 0; */
    border-bottom: 1px solid lightgrey; 
    /* border-radius: 5px; */
    /* box-shadow: 1px 1px 5px #b1aeae; */
    /* background-color: white; */
    /* padding: 15px 0; */
    /* box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    height: auto; */

    /* overflow-y: auto; */
}


/* .Row { */
    /* display: flex; */
    /* height: auto; */
    /* flex-direction: column; */
    /* justify-content: space-around; */
    /* align-items: flex-start; */
    /* overflow-x: auto; */
    /* overflow-y: hidden; */
    /* font-size: 0.8rem; */
    /* padding: 5px; */
    /* margin: 10px; */
    /* overflow: hidden; */
/* } */

.Open {
    max-height: 750px;
    padding: 10px 0 20px 0;
    /* transform: translateY(0); */
    /* visibility: visible; */
    /* background-color: black; */
    /* opacity: 1; */
    /* transition: visibility 0s, opacity 0.5s linear;     */
    transition: all .5s;
    /* overflow-y: auto; */
}

.Close {
    max-height: 0px;
    transform: translateY(-2vh);
    /* z-index: -100; */
    /* display: none; */
    visibility: hidden;
    /* opacity: 0; */
    /* transition: visibility 0s, opacity 0.5s linear; */
    transition: all .3s;
    overflow: hidden;
    
    
    /* transition  */
}

/* .Button {
    padding: 5px 10px;
    text-align: center;
    border-radius: 5px;
    background-color: white;
    color: #464646;
    margin-top: -1px;
    margin-right: 5px;
    border: 1px solid lightgrey;
    box-shadow: 1px 1px 5px #b1aeae;
    font-size: 1.0rem;
    min-width: 210px;
    height: 35px;
    box-sizing: border-box;
} */

/* .Button:hover {
    cursor: pointer;

} */

.Title:hover {
    cursor: pointer;
}