.Click:hover {
    cursor: pointer;
}
  
.outerRing {
    position: relative;
    margin-left: 25px;
    /* left: 0; */
    height: 50px;
    width: 50px;
    background-image: linear-gradient(to right,#ED3A67, #FCBE58, #F5881F);
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /*  Rotate  */
    animation-duration: 10s;
    animation-name: rotate;
    animation-iteration-count: infinite;
}

.innerRing {
    position: relative;
    /* left: 5px; */
    /* top: 5px; */
    height: 42px;
    width: 42px;
    background-image: linear-gradient(0deg, #fff, #fff);
    border-radius: 50%;
}

@keyframes rotate {
    /* 0% {transform:rotate(0deg);} */
    100% {transform:rotate(360deg);}
}

.Streak {
    position: relative;
    left: -34px;
    font-weight: bold;
    color: #F5881F;
    /* margin-left: -30px; */
}